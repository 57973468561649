<template>
    <v-select v-bind:class="{ 'form-control' : true }"
        v-model="selectValue"
        v-bind:data-placeholder="placeholder"
        v-bind:disabled="readonly">
        <template v-for="(option, index) in (presetOptions || options) ">
            <option :value="option.value" v-bind:key="index"
                :disabled=" disables.includes(option.value) "
                item-text="name"
                item-value="code"
                >{{ option.name }}</option>
        </template>
    </v-select>
</template>
<script>
export default {
    props : {
        selectValue : {
            type: String,
            default: ''
        },
        placeholder : {
            type: String,
            default: ''
        },
        options : {
            type: Array,
            default: function(){ return []; }
        },
        preset : {
            type: String,
            default: ''
        },
        disables : {
            type: Array,
            default: function(){ return []; }
        },
        readonly : {
            type: Boolean,
            default: false
        },
        allowClear : {
            type: Boolean,
            default: false
        }
    },
    data : function(){
        return {
            alreadyHasDom : false
        }
    },
    mounted : function(){
        var changedSelVal = this.reLoadData();
        var cv = changedSelVal;
        if( cv === 'ALL' ) cv = ''; // Avoid omit by library
        // Fire event on Init value
        this.$emit('onInitValue', cv);
    },
    computed : {
        presetOptions : function(){
            return selectBoxPresets.call(this, this.preset);
        }
    },
    methods : {
        reLoadData : function(){
            var self = this;
            var selValue = this.selectValue;
            var changedSelVal = selValue;

            var $dom = $(this.$el).select2({
                allowClear : self.allowClear
            });

            var curOptions = this.presetOptions || this.options;

            if( ! selValue ){
                if( curOptions && curOptions.length>0 ){
                    var initVal = curOptions[0].value;
                    changedSelVal = initVal;
                    $dom.val(changedSelVal).trigger('change');
                }
            }
            var existIn = _.find(curOptions, function(v){
                return v.value===selValue;
            });
            if( existIn ){
                changedSelVal = selValue;
                $dom.val(changedSelVal).trigger('change');
            }
            //  init Events
            if( !self.alreadyHasDom ){
                $dom.on('select2:select', function (e) {
                    var cv = e.params.data.id;
                    if( cv === 'ALL' ) cv = ''; // Avoid omit by library
                    self.onChange.call(self, cv);
                });
                $dom.on('select2:clear', function () {
                    self.onChange.call(self, null);
                });
            }
            self.alreadyHasDom = true;
            return changedSelVal;
        },
        onChange : function(v){
            this.$emit("onChange", v);
        }
    },
    watch : {
        preset : function(v){
            this.$nextTick(function() {
                this.reLoadData();
            });
        },
        options : function(v){
            this.$nextTick(function() {
                this.reLoadData();
            });
        },
        selectValue : function(newVal, oldVal){
            var setVal = newVal;
            var avClear = this.allowClear;
            var $dom = $(this.$el).select2({
                allowClear : avClear
            });
            if( setVal === '' ) setVal = 'ALL';// Avoid omit by library
            $dom.val(setVal).trigger('change');
        },
    }
}

var selectBoxPresets = function(key){
    var dynamicOptions = { // May be will have translating timing
        "filter-employee-status" : [
            {name: 'All', value: 'ALL'},    // Avoid omit by library, It will replace '' when call
            // {name: this.$dbTrans('Y', "WCS_USER_APPROVAL_STATUS"), value: 'Y'},
            // {name: 'Waiting for approval', value: 'N'},
            {name: 'Approved', value: 'Approved'},
            {name: 'Not Approved', value: 'Not Approved'},
            {name: 'Suspended', value: 'Suspended'},
            {name: 'Disqualified', value: 'Disqualified'}
        ],
        "filter-employee-property" : [
            {name: 'All', value: 'USER_ID'},
            {name: 'Sales Agency (Master)', value: 'USER_NAME'},
            {name: 'Ads (Sales) Agency', value: 'USER_NICKNAME'},
            {name: 'Client', value: 'MDN'},
            {name: 'Product Agency', value: 'MDN'},
            {name: 'Product Supplier', value: 'MDN'},
            {name: 'Media Agency', value: 'MDN'},
            {name: 'Media Company', value: 'MDN'},
        ],
        "filter-employee-company": [
            {name: 'Company Name', value: 'Company Name'}
        ],
        "filter-report-payment-type":[
            {name: 'All Payment', value: 'All Payment'}
        ],
        "filter-activation-status":[
            {name: 'Status(All)', value: 'Status(All)'},
            {name: 'In Progress', value: 'In Progress'},
            {name: 'Join', value: 'Join'},
            {name: 'Approved', value: 'Approved'},
            {name: 'Suspend', value: 'Suspend'},
        ],
        "filter-report-point":[
            {name: 'All Points', value: 'All Points'},
            {name: 'Received Points', value: 'Reveived Points'},
            {name: 'Used Points', value: 'Used Points'},
            {name: 'Expired Points', value: 'Expired Points'}
        ],
        "filter-report-payment":[
            {name: 'Sort By', value: 'Sort By'},
            {name: 'Member', value: 'Member'},
            {name: 'Company', value: 'Company'},
            {name: 'Product', value: 'Product'},
            {name: 'Transaction ID', value: 'Transaction ID'}
        ],
        "filter-all-customer":[
            {name: 'All Points', value: 'All Points'},
        ],
        "filter-company-mgt":[
            {name: 'Sort By', value: 'Sort By'},
            {name: 'Email', value: 'Email'},
            {name: 'Name', value: 'Name'}
        ],
        "filter-account-status":[
            {name: "Enabled",value:"Enabled"},
            {name: "Disabled",value:"Disabled"},
            {name: "Ready for Approval",value:"Ready for Approval"},
        ],
        "filter-subscriber" : [
            {name: "FB Name", value: "FB Name"},
            {name: "User No", value: "User No"},
            {name: "MSISDN", value: "MSISDN"} 
        ],
        "filter-b2b2c": [
            {name:"UserNo", value:"UserNo"},
            {name: "MSISDN", value: "MSISDN"},
            {name: "Company", value: "Company"},
            {name: "Person in Charge", value: "Person in Charge"},
            {name: "email", value: "email"},
            {name: "Master No", value: "Master No."}
        ],
        "filter-brands":[
            {name: "All Brands", value:"AllBrands"},
            {name: "7-ELEVEN", value:"7-ELEVEN"},
            {name: "AirAsia", value:"AirAsia"},
            {name: "ArcheryAttack", value:"ArcheryAttack"},
            {name: "AUNTIE ANNE'S", value:"AUNTIEANNES"},
            {name: "AutoSweep", value:"AutoSweep"},
            {name: "BASKIN ROBBINS", value:"BASKINROBBINS"},
            {name: "BIGCHILL", value:"BIGCHILL"},
            {name: "BonChon", value:"BonChon"},
            {name: "BROWNIES UNLTD", value:"BROWNIESUNLTD"},
            {name: "CANDY CORNER", value:"CANDYCORNER"},
            {name: "Chef Tony's", value:"Chef Tony's"},
            {name: "Chowking", value:"Chowking"},
            {name: "CoffeeBean & TeaLeaf", value:"CoffeeBean"},
            {name: "COLD LAYERS", value:"COLDLAYERS"},
            {name: "DAVID'S SALON", value: "DAVID'SSALON"},
            {name: "e-Voucher", value: "e-Voucher"},
            {name: "Enchanted Kingdom", value: "EnchantedKingdom"},
            {name: "FAMILYMARAT", value: "FAMILYMART"},
            {name: "freenetConcert", value: "freenetConcert"},
            {name: "GIGA", value: "GIGA"},
            {name: "GMOVIES", value: "GMOVIES"},
            {name: "Grab", value: "Grab"},
            {name: "Jollibee", value: "Jollibee"},
            {name: "LA LOLA", value: "LALOLA"},
            {name: "Mabuhay", value: "Mabuhay"},
            {name: "MCDONALD'S", value: "MCDONALD'S"},
            {name: "MRS. FIELD'S", value: "MRS.FIELD'S"},
            {name: "PERSIA GRILL", value: "PERSIAGRILL"},
            {name: "prefixtest", value: "prefixtest"},
            {name: "Pure Gold", value: "Puregold"},
            {name: "PUREGOLD", value: "PUREGOLD"},
            {name: "QWIKCILVER", value: "QWIKCILVER"},
            {name: "Red Ribbon", value: "RedRibbon"},
            {name: "ROBINSONSMOVIE", value: "ROBINSONSMOVIE"},
            {name: "Test Merchant", value: "Test Merchant"},
            {name: "THESANDWICHGUY", value: "THESANDWICHGUY"},
            {name: "TREATS DELIVERY", value: "TREATSDELIVERY"},
            {name: "YOSHINOYA", value: "YOSHINOYA"},
            {name: "ZALORA", value: "ZALORA"}
        ],
        "filter-products":[
            {name: "Product", value: "Product"}
        ],
        "filter-campaign":[
            {name: "Campaign", value: "Campaign"}
        ],
        "filter-status":[
            {name: "Status", value: "Status"}
        ],
        "filter-title":[
            {name: "Title", value: "Title"}
        ],
        "filter-product-name":[
            {name: "Product Name", value: "ProductName"},
            {name: "Product Code", value: "ProductCode"},
            {name: "Product Description", value: "Description"}
        ],
        "filter-product-sale": [
            {name: "On Sale", value: "OnSale"},
            {name: "Stopped", value: "Stopped"},
            {name: "Finished", value: "Finished"},
        ],
        "filter-domain": [
            {name: "Domain", value: "Domain"},
            {name: "PASA TREATS Main", value: "PASA TREATS Main"},
            {name: "PASA TREATS B2B", value: "PASA TREATS B2B"},
            {name: "SHARE TREATS Main", value: "SHARE TREATS Main"},
            {name: "ABS-CBN TREATS", value: "ABS-CBN TREATS"},
            {name: "facebook TREATS", value: "facebook TREATS"},
            {name: "API", value: "API"},
        ],
        "filter-categories": [
            {name: "Fast Food", value: "Fast Food"},
            {name: "Casual Dining", value: "Casual Dining"},
            {name: "Bread & Dessert", value: "Bread & Dessert"},
            {name: "Beauty & Wellness", value: "Beauty & Wellness"},
            {name: "Lifestyle & Shop", value: "Lifestyle & Shop"},
            {name: "Transpo & Travel", value: "Transpo & Travel"},
            {name: "Game & Digital", value: "Game & Digital"},
        ],
        "filter-on-off": [
            {name: "Enabled", value:"enabled"},
            {name: "Disabled", value:"disabled"}
        ],
        "filter-pin-type": [
            {name: "Internal", value: "internal"},
            {name: "Merchant", value: "Mercahnt"}
        ],
        "filter-account": [
            {name: "ID and Name", value: "IDName"},
            {name: "ID", value: "ID"},
            {name: "Name", value: "Name"}
        ],
        "filter-pin-method": [
            {name: "Auto", value: "Auto"},
            {name: "File Upload", value: "File Upload"},
            {name: "Load Cental", value: "Load Central"},
            {name: "QickCilver", value: "Qwick Cilver"}
        ],
        "filter-shop": [
            {name: "PG", value: "PG"},
            {name: "GLOBE-LOAD", value: "GLOBE-LOAD"},
            {name: "SMART-LOAD", value: "SMART-LOAD"},
            {name: "GRABPAY", value: "GRABPAY"},
            {name: "GCASH", value: "GCASH"},
            {name: "PAYMAYA", value: "PAYMAYA"},
            {name: "CREDIT-DEBIT", value: "CREDIT-DEBIT"},
            {name: "PAYMAYA-DIRECT", value: "PAYMAYA-DIRECT"},
            {name: "GCASH-V2", value: "GCASH-V2"},
            {name: "POINTS", value: "POINTS"},
            {name: "WebConnect Points", value: "WebConnect Points"},
            {name: "BIZ-WALLET-POINTS", value: "BIZ-WALLET-POINTS"},
            {name: "OPEN-API", value: "OPEN-API"},
            {name: "SYSTEM-EXTRACT-POINTS", value: "SYSTEM-EXTRACT-POINTS"},
            {name: "BIZ-TREATS-POINTS", value: "BIZ-TREATS-POINTS"},
            {name: "WEB-CONNECT-V2", value: "WEB-CONNECT-V2"}
        ],
        "filter-client": [
            {name: "Client", value: "Client"},
            {name: "atome2019", value: "atome2019"},
            {name: "Avanza", value: "Avanza"},
            {name: "bestinc", value: "bestinc"},
            {name: "blackarrow", value: "blackarrow"},
            {name: "clienst333", value: "clienst333"},
            {name: "Coins.ph", value: "Coins.ph"},
            {name: "emerson", value: "emerson"},
            {name: "freenet", value: "freenet"},
            {name: "GiGa", value: "GiGa"},
            {name: "globerush", value: "globerush"},
            {name: "homecredit", value: "homecredit"},
            {name: "ingrammicro", value: "ingrammicro"},
            {name: "LAZADA", value: "LAZADA"},
            {name: "luckywheel", value: "luckywheel"},
            {name: "Maxicare", value: "Maxicare"},
            {name: "mict", value: "mict"},
            {name: "oauthtest1", value: "oauthtest1"},
            {name: "onevoyager", value: "onevoyager"},
            {name: "Oriente Express Tech", value: "Oriente Express Tech"}
            
        ],
        "filter-carrier": [
            {name: "All Carrier", value: "AllCarrier"},
            {name: "GLOBE", value: "GLOBE"},
            {name: "SMART", value: "SMART"},
            {name: "SUN", value: "SUN"},
        ],
        "filter-status-purchase-inquiry": [
            {name: "Success", value: "Success"},
            {name: "Cancel", value: "Cancel"},
            {name: "Pending", value: "Pending"},
        ],
        "filter-ecommerce": [
            {name: "E-Commerce", value: "E-Commerce"},
            {name: "LAZADA", value: "LAZADA"},
            {name: "SHOPEE", value: "SHOPEE"},
            {name: "GAMEPINFORLAZADA", value: "GAMEPINFORLAZADA"},
        ],
        "filter-redemption":[
            {name: "Show redemption only", value:"redeemed"}
        ],
        "filter-ts-carrier": [
            {name:"All Carrier", value:"All Carrier"},
            {name:"GLOBE", value:"GLOBE"},
            {name:"SMART", value:"SMART"},
            {name:"SUN", value:"SUN"},
            {name:"TW", value:"TW"},
        ],
        "filter-ts-brand":[
            {name:"All Brands", value:"All Brands"},
            {name:"BRO POSTPAID", value:"BRO POSTPAID"},
            {name:"BRO PREPAID", value:"BRO PREPAID"},
            {name:"BUDDY", value:"BUDDY"},
            {name:"POSTPAID", value:"POSTPAID"},
            {name:"PREPAID", value:"PREPAID"},
            {name:"SUN-POSTPAID", value:"SUN-POSTPAID"},
            {name:"SUN-PREPAID", value:"SUN-PREPAID"},
            {name:"TM", value:"TM"},
            {name:"TNT", value:"TNT"},
            {name:"TW", value:"TW"},
        ],
        "filter-points-reward": [
            {name: "Points and Rewards", value:"PointsRewards"},
            {name: "Points", value:"Points"},
            {name: "Rewards", value:"Rewards"},
        ],
        "filter-coupon-mgt":[
            {name: "Mobile No", value:"MobileNo"},
            {name: "Remarks", value:"Remarks"}
        ],
        "filter-qtype": [
            {name: "Sign up/Sign in", value:"SignupSignin"},
            {name: "Product", value:"Product"},
            {name: "Payment", value:"Payment"},
            {name: "Treats Code", value:"Treats Code"},
            {name: "Merchant/Redemption", value:"Merchant/Redemption"},
            {name: "Others", value:"Others"},
        ],
        "filter-qsite": [
            {name: "CMS", value:"CMS"},
            {name: "B2B", value:"B2B"},
            {name: "B2C", value:"B2C"},
            {name: "B2B2C", value:"B2B2C"},
        ],
        "filter-b2b-category":[
            {name: "B2B", value: "B2B"},
        ],
        "filter-b2b-mileage-type": [
            {name: "Add Up Points", value: "Add Up Points"},
            {name: "Use Points", value: "Use Points"}
        ],
        "filter-code-list": [
            {name: "Acquisitor", value: "Acquisitor"},
            {name: "Age Range", value: "AgeRange"},
            {name: "Area Code", value: "Area Code"},
            {name: "B2B Event Send Gubun", value: "B2B Event Send Gubun"},
            {name: "B2B Event Sending Type", value: "B2B Event Sending Type"},
            {name: "B2B Event Status", value: "B2B Event Status"},
            {name: "B2B User Status", value: "B2B User Status"},
            {name: "B2B User Type", value: "B2B User Type"},
            {name: "B2B Company Email", value: "B2B Company Email"},
            {name: "B2B Points", value: "B2B Points"},
            {name: "B2C Points", value: "B2C Points"},
            {name: "Bank Name", value: "Bank Name"},
            {name: "BIZTREATS_POINTS", value: "BIZTREATS_POINTS"},
            {name: "BIZ_USER_APPROVAL_STATUS", value: "BIZ_USER_APPROVAL_STATUS"},
            {name: "Channel", value: "Channel"},
            {name: "Channel Type", value: " Channel Type"},
            {name: "DISPLAY_GOODS_STATUS", value: "DISPLAY_GOODS_STATUS"},
            {name: "ERROR_CODE", value: "ERROR_CODE"},
            {name: "IP Address Type", value: "IP Address Type"},
            {name: "LIMIT Amount Gubun", value: "LIMIT Amount Gubun"},
            {name: "MERCHANT_CATEGORY_MENU_TYPE", value: "MERCHANT_CATEGORY_MENU_TYPE"},
            {name: "MERCHANT_CATEGORY_WEBCON_MENU", value: "MERCHANT_CATEGORY_WEBCON_MENU"},
            {name: "MERCHANT PARTNER_BIZ_MENU_TYPE", value: "MERCHANT PARTNER_BIZ_MENU_TYPE"},
            {name: "MERCHANT_PARTNER_WEBCON_MENU", value: "MERCHANT_PARTNER_WEBCON_MENU"},
            {name: "MMS Result Code", value: "MMS Result Code"},
            {name: "MMS Send Type", value: "MMS Send Type"},
            {name: "MMS Sending Gubun", value: "MMS Sending Gubun"},
            {name: "MMS Sending Status", value: "MMS Sending Status"},
            {name: "Operator", value: "Operator"},
            {name: "Payment Method", value: "Payment Method"},
            {name: "PG_CHANNEL", value: "PG_CHANNEL"},
            {name: "PIN Creation Type", value: "PIN Creation Type"},
            {name: "PIN Group Gubun", value: "PIN Group Gubun"},
            {name: "PIN Group Status", value: "PIN Group Status"},
            {name: "PIN Status Code", value: "PIN Status Code"},
            {name: "POS Fail Code", value: "POS Fail Code"},
            {name: "POS Status Code", value: "POS Status Code"},
            {name: "Product Status", value: "Product Status"},
            {name: "Product Type", value: "Product Type"},
            {name: "Question Type", value: "Question Type"},
            {name: "Random Brand Order", value: "Random Brand Order"},
            {name: "Random Display Order", value: "Random Display Order"},
            {name: "Re-Send Type", value: "Re-Send Type"},
            {name: "Redeem Place", value: "Redeem Place"},
            {name: "Refund Type", value: "Refund Type"},
            {name: "Send Type", value: "Send Type"},
            {name: "SEND_STATUS_MT", value: "SEND_STATUS_MT"},
            {name: "Settlement Type", value: "Settlement Type"},
            {name: "Site Gubun", value: "Site Gubun"},
            {name: "SITE_DOMAIN_TYPE", value: "SITE_DOMAIN_TYPE"},
            {name: "SITE_PUBLISH", value: "SITE_PUBLISH"},
            {name: "SMS MO Mode", value: "SMS MO Mode"},
            {name: "STICKER CATEGORY", value: "STICKER CATEGORY"},
            {name: "SYSTEM MAINTENANCE BIZ", value: "SYSTEM MAINTENANCE BIZ"},
            {name: "SYSTEM MAINTENANCE FB", value: "SYSTEM MAINTENANCE FB"},
            {name: "SYSTEM MAINTENANCE GCASH", value: "SYSTEM MAINTENANCE GCASH"},
            {name: "SYSTEM MAINTENANCE GLOBE", value: "SYSTEM MAINTENANCE GLOBE"},
            {name: "SYSTEM_MAINTENANCE_AIRASIA", value: "SYSTEM MAINTENANCE BIZ"},
            {name: "SYSTEM_MAINTENANCE_CREDIT-DEBIT", value: "SYSTEM_MAINTENANCE_CREDIT-DEBIT"},
            {name: "SYSTEM_MAINTENANCE_GRABPAY", value: "SYSTEM_MAINTENANCE_GRABPAY"},
            {name: "SYSTEM_MAINTENANCE_LOADCENTRAL", value: "SYSTEM_MAINTENANCE_LOADCENTRAL"},
            {name: "SYSTEM_MAINTENANCE_PASA&SHARE", value: "SYSTEM_MAINTENANCE_PASA&SHARE"},
            {name: "SYSTEM_MAINTENANCE_PAYMAYA", value: "SYSTEM_MAINTENANCEPAYMAYA"},
            {name: "SYSTEM_MAINTENANCE_PAYMAYA-DIRECT", value: "SYSTEM_MAINTENANCE_PAYMAYA_DIRECT"},
            {name: "SYSTEM_MAINTENANCE_POINTS", value: "SYSTEM_MAINTENANCE_POINTS"},
            {name: "SYSTEM_MAINTENANCE_SMART", value: "SYSTEM_MAINTENANCE_SMART"},
            {name: "User Operator", value: "User Operator"},
            {name: "USER_CHANNEL", value: "USER_CHANNEL"},
            {name: "USER_REWARD_POINTS", value: "USER_REWARD_POINTS"},
            {name: "VOC Processing Status", value: "VOC Processing Status"},
            {name: "VOC Type", value: "VOC Type"},
            {name: "WCS_POINTS", value: "WCS_POINTS"}
        ],
        'filter-whitelist': [
            {name: "Remarks and No", value: "Remarks and No"},
            {name: "Remarks", value: "Remarks"},
            {name: "Number", value: "Number"},
        ],
        'filter-supplier': [
            {name: "Supplier", value: "Supplier"}
        ],
        'filter-masterProduct': [
            {name: "Master Product", value: "Master Product"}
        ],
        'filter-supplierStatus': [
            {name: "On Sale", value: "On Sale"},
            {name: "Stopped", value: "Stopped"},
            {name: "Finished", value: "Finished"}
        ],
        'filter-offline': [
            {name: "None", value: "None"},
            {name: "Offline Goods", value: "Offline Goods"},
            {name: "Delivery Goods", value: "Delivery Goods"}
        ],
        'filter-infotype': [
            {name: "Store Info", value: "Store Info"},
            {name: "Digital Info", value: "Digital Info"},
            {name: "Custom Info", value: "Custom Info"}
        ],
        'filter-order':[
            {name: "Referring Display Order", value: "Referring Display Order"},
            {name: "Random", value: "Random"}
        ],
        'filter-yn': [
            {name: "yes", value:"yes"},
            {name: "no", value:"no"}
        ],
        'filter-points-type': [
            {name: "percent", value:"percent"},
            {name: "points", value:"points"}
        ],
        
        // I just changed this for demo purposes
    };
    return dynamicOptions[key];
};
</script>
<style scoped>

</style>